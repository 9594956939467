<template>
  <div class="create_article_container">
    <header class="header">
      <van-nav-bar
        title="写优质文章"
        left-arrow
        @click-left="handleBlack"
        safe-area-inset-top
      >
      </van-nav-bar>
    </header>

    <article class="article">
      <div class="article_info">
        <span>文章标题</span>
        <van-field
          v-model="articles.title"
          rows="2"
          autosize
          type="textarea"
          placeholder="请输入你要添加的内容"
          show-word-limit
        />
        <span>文章封面</span>
        <div class="article_img">
          <image-upload
            v-if="!loading"
            :busiId="busi_Id"
            :busiCat="busi_Cat"
            :groupId="group_Id_index"
          ></image-upload>
        </div>
        <span>海报图</span>
        <div class="article_img">
          <image-upload
              v-if="!loading"
              :busiId="busi_Id"
              :busiCat="`poster`"
              :groupId="`sharePosterPic`"
          ></image-upload>
        </div>
      </div>
      <div class="article_editor">
        <rich-editor
          v-model="articles.content"
          :busiId="busi_Id"
          :busiCat="busi_Cat"
          :groupId="group_Id_context"
        ></rich-editor>
      </div>
      <div class="star-and-con">
        <van-field
          label="点赞数"
          placeholder="请输入"
          type="number"
          clearable
          v-model="articles.countOfLikes"
        />
        <van-field
          label="收藏数"
          placeholder="请输入"
          clearable
          type="number"
          v-model="articles.countOfCollection"
        />
      </div>
      <div class="is-show-card">
        <div class="show-switch"><span>在我的名片显示</span><van-switch v-model="showInCard" size="20px" inactive-color="#BFBFBF" @change="handleShowInCard"/></div>
          <van-field
          label="名片排名"
          placeholder="数字越大越靠前"
          clearable
          type="number"
          v-model="articles.cardSort"
          v-if="articles.isCardRecommend"
        />
      </div>

      <div class="footer">
        <van-grid clickable :column-num="2" safe-area-inset-bottom>
          <van-grid-item
            ><van-button
              round
              type="primary"
              block
              color="#BFBFBF"
              @click="handleBlack"
              >返回</van-button
            ></van-grid-item
          >
          <van-grid-item
            ><van-button
              round
              type="primary"
              block
              color="#FF6E01"
              @click="handleCreateArticle(articles)"
              >保存</van-button
            ></van-grid-item
          >
        </van-grid>
      </div>
    </article>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { useRoute, useRouter } from "vue-router";
import RichEditor from "../../components/RichEditor.vue";
import ImageUpload from "../../components/FileUpload/ImageUpload.vue";
import { findNextId, createArticle } from "../../api/huokeArticle";
import { Toast } from "vant";
export default {
  components: {
    RichEditor,
    ImageUpload,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const state = reactive({
      showInCard:false,
      loading: false,
      busi_Id: "",
      busi_Cat: "article",
      group_Id_index: "articleIndexPic",
      group_Id_context: "articleContentPic",
      articleContent: "",
      articles: {
        articleId: "", //文章id
        title: "", //标题
        content: "", //内容
        countOfLikes: null, //点赞
        countOfCollection: null, //收藏
        cardSort:null,
        isCardRecommend:0
      },
    });
    //获取文章id
    const loadArticleId = async () => {
      state.loading = true;
      let data = await findNextId();
      state.loading = false;
      state.articles.articleId = data;
      state.busi_Id = data;
    };
    loadArticleId();
    const handleBlack = () => {
      router.push({
        name: "selectCreateType",
        // params: {
        //   oneType: Number(route.query.myType) === 1 ? 3 : 0,
        //   twoType: route.query.twoType,
        // },
      });
    };
    //开关
    const handleShowInCard=()=>{
      if(state.showInCard){
        state.articles.isCardRecommend = 1
      }else{
        state.articles.isCardRecommend = 0
      }
    }
    //创建文章
    const handleCreateArticle = async (params) => {
      let result = await createArticle(params);
      if (result) {
        Toast.success("保存成功");
        console.log(route.query)
        router.replace({
          path: "/articleVisitors/visitorDetails",
          query: {
            id: state.articles.articleId,
            // myType: Number(route.query.myType) === 1 ? 3 : 0,
            // twoType: route.query.twoType,
          },
        });
      }
    };
    return { handleShowInCard,handleCreateArticle, handleBlack, ...toRefs(state) };
  },
};
</script>
<style lang="scss">
.create_article_container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f1f3f6;

  .header {
    .van-nav-bar__arrow {
      color: #333333;
    }
    .van-nav-bar__title {
      color: #333333;
      font-weight: 400;
    }
  }
  .article {
    flex: 1;
    overflow-x: hidden;
    .article_info {
      padding: 17px;
      text-align: left;
      border-radius: 5px;
      > span {
        color: rgba(0, 0, 0, 0.84);
        font-size: 16px;
        font-weight: 400;
        display: block;
        margin-bottom: 7px;
      }
      > span:nth-child(3) {
        margin-top: 30px;
      }
    }
    .article_editor {
      text-align: left;
      font-size: 14px;
      img{
        max-width: 100%;
        vertical-align: middle;
      }
      p{
        margin: 0 !important;
      }
    }
    .star-and-con {
      padding: 0 16px;
      .show-switch{
        color: black;
        font-size: 14px;
        font-weight: 400;
        padding: 0 16px;
        height: 44px;
        margin-top: 8px;
        border-radius: 4px;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
       .van-cell {
        margin-top: 8px;
        border-radius: 4px;
      }
      .van-cell__title{
        font-size: 14px;
        font-weight: 400;
        color: black;
      }
    }
    .is-show-card{
      padding: 0 16px;
      .show-switch{
        color: black;
        font-size: 14px;
        font-weight: 400;
        padding: 0 16px;
        height: 44px;
        margin-top: 8px;
        border-radius: 4px;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
       .van-cell {
        margin-top: 8px;
        border-radius: 4px;
      }
      .van-cell__title{
        font-size: 14px;
        font-weight: 400;
        color: black;
      }

    }
    .button_confirm {
      display: flex;
      justify-content: space-between;
      padding: 16px;

      > div {
        width: 160px;
        height: 44px;
        color: #fff;
        font-size: 14px;
        border-radius: 8px;
        text-align: center;
        line-height: 44px;
        background-color: #bfbfbf;
      }
      .button_confirm_save {
        background: linear-gradient(0deg, #ff6e01, #ff6e01);
      }
    }
    .footer {
      .van-button--round {
        border-radius: 8px;
        height: 44px;
        width: 160px;
      }
      .van-hairline {
        background-color: #f7f7f7;
      }
    }
  }
}
</style>
